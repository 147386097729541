import React, { useEffect, useState } from "react"
import Layout from "../modules/layout"
import SEO from "../modules/seo"
import { graphql } from "gatsby";
import { renderPreviewComponents } from "../components";
const contentful = require('contentful')

const PreviewPage = ({ data: { allContentfulSiteSettings }}) => {
  const siteSettings = allContentfulSiteSettings.edges[0].node
  const client = contentful.createClient({
    space: 'f999py1goepl',
    accessToken: 'H0HUyLE_3hKOKkjhe9LOSIT3YCiMWU9Go7Bz0FEUwqQ',
    host: 'preview.contentful.com'
  })
  const [contentComponents, setContentComponents] = useState([]);
  const [locale, setLocale] = useState('de');

  useEffect(() => {
    const url = new URL(window.location)
    const pageId = url.searchParams.get("pageId")
    client.getEntry(pageId)
    .then((entry) => {
      setLocale(entry.sys.locale)
      setContentComponents(entry.fields.contentComponents)
    })
    .catch(console.error)
  })

  return (
    <Layout siteSettings={siteSettings}>
      <SEO title="Vorschau" />
      {contentComponents && renderPreviewComponents(contentComponents, locale)}
    </Layout>
  )
}

export const pageQuery = graphql`
  query siteSettingsPreview {
    allContentfulSiteSettings {
      edges {
          node {
              siteName
              siteDescription
              siteAuthor
              copyright
              navigation {
                  slug
                  name
              }
              logo {
                fixed(width: 260, height: 80) {
                  base64
                  width
                  height
                  src
                  srcSet
                }
              }
          }
      }
    }
  }
`

export default PreviewPage
